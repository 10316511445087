@use "variables" as *;
@use "helpers" as *;
@use "placeholders/typography" as *;
@use "placeholders/shadow" as *;
@use "placeholders/forms" as *;
@use "placeholders/icons" as *;

// RADIO BUTTONS
.custom-radio {
  // diameters
  --size-outer-circle: 2rem;
  --size-inner-dot: 1.2rem;

  padding: 0;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  .custom-control-label {
    &.small {
      --size-outer-circle: 1.6rem;
      --size-inner-dot: 0.8rem;
    }

    padding: 0 0 0 calc(var(--size-outer-circle) + #{$padding-1});
    min-height: var(--size-outer-circle);
    margin: 0;

    @extend %lh-24;

    display: flex;
    align-items: center;

    &::before {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: #fff;
      border: 1px solid $color-dark-grey;
      box-shadow: none;
      height: var(--size-outer-circle);
      width: var(--size-outer-circle);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: #fff;
      height: var(--size-outer-circle);
      width: var(--size-outer-circle);
    }

    &::after {
      border-radius: 50%;
      background-image: none;
      background-color: $color-verisk-action-blue;
      background-size: 100%;
      height: var(--size-inner-dot);
      width: var(--size-inner-dot);
      top: 50%;
      left: calc(var(--size-outer-circle)/2);
      transform: translateY(-50%) translateX(-50%);
    }
  }

  &.custom-control-inline {
    margin-right: $margin-2;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
}

// CHECKBOXES
.custom-checkbox {
  min-height: 1.8rem;
  padding: 0;
  display: flex;

  /* stylelint-disable-next-line no-descending-specificity */
  .custom-control-label {
    @extend %flex-row;
    @extend %align-start;
    @extend %checkbox-before-small;

    &::after {
      content: none;
    }
  }

  .custom-control-input {
    display: none;

    &:disabled {
      ~ .custom-control-label {
        color: $color-dark-grey;
      }
    }

    &:checked {
      ~ .custom-control-label::before {
        @extend %checkbox-active;
      }
    }

    &:indeterminate {
      ~ .custom-control-label::before {
        @extend %checkbox-indeterminate;
      }
    }
  }
}

// INPUTS
.form-control {
  border-radius: $border-radius;
  border: 1px solid $color-verisk-black-20;
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  height: 3.6rem;
  width: 100%;
  align-items: center;
  color: $color-verisk-black-85;
  box-shadow: none;

  &:not(.disabled):not(:disabled) {
    &:focus,
    &:hover,
    &:focus-within {
      border-color: $color-verisk-action-blue;
      color: $color-verisk-black-85;
      background-color: $color-white;
      box-shadow: none;
    }

    &.invalid {
      border-color: $color-verisk-behavioural-red;
    }
  }
}

.gradient-error-icon {
  &::after {
    position: absolute;
    right: 9px;
    content: "";
    background: url(#{$img-icon-path}/alert-circle.svg) no-repeat 0% center;
    background-size: 20px;
    width: 25px;
    height: 30px;
    top: 9px;
  }
}

span.custom-control {
  padding-left: 2.2rem;
}

// select styling based on https://www.filamentgroup.com/lab/select-css.html
.select-css {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: $color-verisk-ext-blue-1;
  line-height: 1;
  padding: 1rem 2.3rem 1rem 0.8rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 0.1rem solid $color-dark-grey;
  border-radius: 0.4rem;
  appearance: none;
  background-color: $color-white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 50%, 0 0;
  background-size: 1rem auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: $color-verisk-action-blue;
}

.select-css:focus {
  border-color: $color-verisk-action-blue;
  outline: none;
}

.select-css option {
  font-weight: 400;
}

// DROPDOWN
.dropdown-toggle::after {
  content: none;
}

.dropdown-menu {
  @extend %shadow;

  width: 100%;
  margin: $margin-1 0;
  padding: 0;
  border-radius: $border-radius;
  border: 1px solid $color-dark-grey;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  right: 0;
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1050;
  padding: 0.8rem;
}

.dropdown-item {
  border-radius: $border-radius;
  padding: 1rem 0.8rem;
  font-size: 1.4rem;
  min-height: 3.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  color: $color-verisk-black;
  margin: 0;
  white-space: normal;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: $color-verisk-action-blue-10;
  color: $color-verisk-black;
}

.dropdown-item.active, .dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: $color-verisk-action-blue-10;
}

.mc-dropdown-icon {
  @extend %flex;
  @extend %align-center;
  @extend %icon-dropdown-size;

  transition: transform $transition-time;
}

.dropdown,
.dropup {
  &.show {
    .mc-dropdown-icon {
      transform: rotate(180deg);
    }
  }
}

// VALIDATION ERROR
.mc-invalid,
.mc-invalid:focus,
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
input.invalid,
textarea.invalid {
  border: $border-width solid $color-verisk-behavioural-red;
}

.validation-error {
  @extend %relative;

  color: $color-verisk-behavioural-red;
  font-size: $font-size-2;
  margin-left: $margin-3;
  margin-top: $margin-1;

  >::before {
    @extend %icon-error;
    @extend %absolute;

    top: 50%;
    transform: translateY(-50%) translateX(-150%);
    content: "";
  }
}

.mc-inactive {
  pointer-events: none;
  cursor: default;
  background-color: #e9ecef;
}

.mc-placeholder {
  color: $color-verisk-ext-blue-1;
}

.dropdown-menu-header {
  padding: $padding-2 $padding-1 $padding-1 $padding-1;
}

.mc-dropdown-count-header {
  margin-bottom: $margin-2;
  padding: 0 $padding-1;
}

.dropdown-item-title {
  padding: $padding-1;
}

.dropdown-menu-body {
  max-height: 24rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin: $margin-1;

  .dropdown-item {
    cursor: pointer;
    font-size: 14px;
    min-height: 27px;
    white-space: normal;
  }

  .no-data-available-container,
  .no-matching-results-container,
  .mc-item-loading-container,
  .mc-error-container {
    padding: $padding-1;
  }
}

// Override default browser stylesheet/user agent stylesheet
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

// LABELS
label {
  @extend %lh-24;

  color: $color-verisk-ext-blue-1;
  font-weight: $font-weight-medium;
  margin-bottom: $margin-1;
}

// INPUTS
input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="checkbox"] {
  cursor: pointer;
}

textarea {
  min-height: 8rem;
}

select::-ms-expand {
  display: none;
}

.form-control::-ms-clear {
  display: none;
}

// SCROLLBAR
::-webkit-scrollbar {
  width: 0.9rem;
  height: 0.9rem;
  background-color: $color-snow;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: $color-dark-grey;
}

.form-wrapper {
  max-height: 40rem;
  overflow-y: auto;
}

.form-helper-text {
  color: $color-verisk-black-85;
  font-size: $font-size-2;
  font-weight: $font-weight-regular;
  margin-bottom: $margin-1;
}

// PLACEHOLDERS
::input-placeholder { /* Chrome/Opera/Safari */
  color: $color-label-grey;
  font-style: italic;
}

::placeholder { /* Firefox 19+ */
  color: $color-label-grey;
  font-style: italic;
}

:input-placeholder { /* IE 10+ */
  color: $color-label-grey;
  font-style: italic;
}

:placeholder { /* Firefox 18- */
  color: $color-label-grey;
  font-style: italic;
}
